.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  font-family: Gilroy;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  color: #666f76;
  text-align: right;
  padding: 0px 10px;
  opacity: 0.6;

}