@import "../../variables.scss";

.navbar {
  box-shadow: 0 2px 11px 0 rgba(0, 0, 0, 0.05) !important;
  .logo {
    max-width: 120px;
  }
  .dropdown {
    font-weight: bold !important;
    .dropdown-toggle::after {
      margin-left: 0.5em !important;
    }
  }
  .dropdown-menu {
    right: -25% !important;
    left: auto !important;
    width: 220px !important;
    border: 0px !important;
    border-radius: 0px !important;
    margin-top: 10px;
    top: 120% !important;
    text-align: right !important;
    font-size: 18px;
    font-weight: bold !important;
    font-stretch: normal;
    font-style: normal;
    line-height: 3em;
    color: #0c2340;

    z-index: 3;
    .disabled {
      color: #6c757d !important;
    }

    .active {
      background-color: transparent !important;
    }
    .dropdown-item {
      font-weight: bold !important;
      color: #0c2340;
      font-size: 18px;
      font-weight: bold;
      font-stretch: normal;
      margin-top: 10px;
      font-style: normal;
      line-height: normal;
      letter-spacing: 3px;
    }
  }
}
