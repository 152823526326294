.presentation-box {
  // max-width: 300px;
  margin-top: 10px;
  height: calc(100% - 20px);
  .presentation-image {
    padding: 20px 40px;
  }
  .presentation-headline {
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: 0.86px;
    text-align: center;
  }
}
