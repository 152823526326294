.presentation-frame {
  // width: 70%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  // height: 100%;
}
.outer-box {
  max-width: 700px;
  /* Extra small devices (phones, 600px and down) */
  
  // margin: 50px;
  width: 100%;
  margin: 0 auto;
}
.presentation-container {
  width: 100%;
  padding-top: 75%;
  position: relative;
  margin: 0 auto;
  align-self: center;
  // max-width: 500px;
  // max-height: 596px;
  // height: 596px;
}
.presentation-header {
  text-align: center;
  margin-bottom: 50px;
  margin-top: 0px;
}

.actions {
  text-align: center;
  width: 100%;
  margin-top: 10px;
  font-size: 34px;
  .icon {
    width: 35px;
  }
}
