@import "./theme.scss";
@import url("https://cdn.rawgit.com/mfd/09b70eb47474836f25a21660282ce0fd/raw/e06a670afcb2b861ed2ac4a1ef752d062ef6b46b/Gilroy.css");

body {
  color: $color !important;
  font-family: "Gilroy", sans-serif !important;
  a {
    color: $color !important;
  }
  h1 {
    font-family: Gilroy;
    font-size: 32px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.13;
    letter-spacing: 3.2px;
    text-align: center;
    color: $color !important;
  }
  h2 {
    font-family: Gilroy;
    font-size: 32px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.13;
    letter-spacing: 3.2px;
    text-align: center;
  }
}
