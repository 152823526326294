.mid-box {
  margin: 0 auto;
  height: 300px;
  align-self: center;
  max-width: 630px;
  text-align: center;
  .buttons {
    .btn:not(:first-child) {
      margin-left: 10px;
    }
  }
}