.home-container {
  
  .header {
    text-align: center;
    width: 100%;
    margin-top:10px;
  }
  .lib-row {
    margin-top: 20px;
    margin-left: -15px;
    margin-right: -15px;
    margin-bottom: 20px;
    @media (min-width: 1281px) {
      .presentation-col:not(:first-child) {
        padding-left: 10px;
      }
    }
    @media (min-width: 1025px) and (max-width: 1280px) {
  
      .presentation-col:not(:first-child) {
        padding-left: 10px;
      }
      
    }
  }
}
