.fullscreen-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.96);
  display: flex;
  z-index: 100;
  .close-button {
    position: fixed;
    right: 0px;
    cursor: pointer;
    top: 0px;
    // color: white;
    z-index: 1000;
    // background-color: white;
    padding: 5px;

    svg {
      width: 35px;
      height: 35px;
    }
  }
  .outer-box {
    align-self: center;
    // max-width: 70% !important;
    @media only screen and (max-width: 600px) and (orientation: landscape) {
      max-width: 550px !important;
    }

    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (min-width: 600px) and (orientation: landscape) {
      max-width: 400px !important;
    }

    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (min-width: 768px) and (orientation: landscape) {
      max-width: 500px !important;
      // max-height: 90%;
    }

    /* Large devices (laptops/desktops, 992px and up) */
    @media only screen and (min-width: 992px) and (orientation: landscape) {
      max-width: 1000px !important;
    }

    /* Extra large devices (large laptops and desktops, 1200px and up) */
    @media only screen and (min-width: 1200px) and (orientation: landscape) {
      max-width: 1000px !important;
    }
    .fullscreen-box {
      margin: 0 auto;
      display: flex;
      position: relative;
      padding-top: 75%;
      align-items: center;
      justify-content: center;
      width: 100%;
      .iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
}
